import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';

import { Navigate, redirect, useParams, Redirect } from "react-router-dom";
import ProductsList from "../productsList/productsList";
import Modal from "../modal/modal";
import ImgModal from "../modal/imgModal";
import { Button } from '@mui/material';
import { useCookies, Cookies } from 'react-cookie';
import axios, { } from "axios";


const ImgUpload = (props) => {
    const [image, _setImage] = useState();
    const inputFileRef = createRef();

    const cleanup = async () => {
        URL.revokeObjectURL(image && props.image);
        inputFileRef.current = null;
    };
    const setImage = async (newImage) => {
        if (image) {
            await cleanup();
        }
        _setImage(newImage)
    }
    const handleOnChange = (event) => {
        const newImage = event.target.files[0];
        if (newImage) {
            setImage(URL.createObjectURL(newImage))
        }
        props.imageUpload(event)
    }
    return (
        <div>
            <input
                ref={inputFileRef}
                accept="image/*"
                hidden
                id="product-image-upload"
                type="file"
                onChange={handleOnChange}
            />
            <label htmlFor="product-image-upload">
                <Button
                    component="span">
                    {image ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
                    {image ? "Uploaded" : "upload"}
                </Button>
            </label>
        </div>
    )
}
export default ImgUpload;
import React, { useState, useEffect, createRef } from "react";
import styles from "./productDescription.module.scss"
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';

import { Navigate, redirect, useParams, Redirect, useNavigate } from "react-router-dom";
import ProductsList from "../productsList/productsList";
import Modal from "../modal/modal";
import ImgModal from "../modal/imgModal";
import { Button } from '@mui/material';
import axios, { } from "axios";
import ImgUpload from "../imgUpload/imgUpload";

export default function ProductDescription(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const { paintingId } = useParams()
    const [modal, setModal] = useState(false);
    const [imgPreview, setImgPreview] = useState(false);
    const [logo, setLogo] = useState("")
    const [imageUpload,] = useState({})
    const [, setImg] = useState({})
    const [thisPainting, setThisPainting] = useState([{}])

    const isCreate = paintingId === "0" ? true : false;
    const [inputData, setInputData] = useState({});
    const [dateToDisplay, setDateToDisplay] = useState("2000-01-01")

    const navigate = useNavigate();

    // const thisProduct = productsData.find(prod => prod.id === productId)

    const getProducts = async () => {
        setIsLoading(true);
        if (paintingId === "0") {
            setInputData({
                "price": 0.00,
                "img_location": ["../../question-mark.svg"],
                "label": "",
                "creationDate": new Date().getDate(),
                "size": "0x0",
                "selected": false,
                "sold": false,
                "technique": "",
                "quantity": 1
            })
            setDateToDisplay(new Date().toISOString().split('T')[0])
        }
        else {
            const response = await fetch("https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/products/" + paintingId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            const data = await response.json();
            setInputData(data)
            console.log(data)
            setDateToDisplay(new Date(data.creationDate).toISOString().split('T')[0])
        }
        setIsLoading(false);
    }

    window.addEventListener('DOMContentLoaded', (event) => {
        const inputField = document.getElementById('price');

        inputField.addEventListener('click', () => {
            inputField.select();
        });
    });

    useEffect(() => {
        getProducts();
    }, [])


    useEffect(() => {
        const isModalActive = () => {
            if (modal) {

            }
            else {
                document.body.style.overflow = 'unset';
            }
        }
        isModalActive()
    }, [modal]);

    const selectAll = (e) => {
        e.target.select();
    };

    const handleImg = async (e) => {
        if (e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            })
            setLogo(e.target.files[0]);

            await uploadImage(e.target.files[0]);
        }
    }

    const uploadImage = async (logov) => {
        setIsLoadingImage(true)
        setTimeout(async () => {
            console.log(logov)
            await profileUpload(logov)
            await setIsLoadingImage(false)
        }, "10");
    }

    // useEffect(() => {
    //     if (logo) {
    //         console.log("keliam foto")
    //         uploadImage()
    //     }
    // }, [logo])


    const handleChange = (e) => {
        const { id, checked, value } = e.target;
        if (id === "sold") {
            setInputData(prevValue => ({ ...prevValue, [id]: checked }));
        }
        else if (id === "date") {
            let data = new Date(`${value}`);
            data = data.toISOString()
            data = data.replace("Z", "+00:00");
            const inputData_ = inputData;
            inputData_.creationDate = data
            setInputData(inputData_);
            setDateToDisplay(new Date(inputData_.creationDate).toISOString().split('T')[0])
            console.log(paintingId)
        }
        else if (id === "price") {
            // let float = value;
            // if (isNaN(float)) {
            //     setInputData(prevValue => ({ ...prevValue, [id]: 0 }));
            //     return;
            // }
            // let roundedValue = Math.round(float * 100) / 100;
            // setInputData(prevValue => ({ ...prevValue, [id]: roundedValue }));
            setInputData(prevValue => ({ ...prevValue, [id]: checkPriceValue(value.replace(',', '.')) }))
        }
        else {
            setInputData(prevValue => ({ ...prevValue, [id]: value }));
        }
    };

    const checkPriceValue = (value) => {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/;
        return value.match(regex)[0]
    }

    const profileUpload = async (file) => {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("upload_preset", "kagvobloswn5rv0")
        let data = "";
        await axios.post(
            "https://api.cloudinary.com/v1_1/dcjr3u39o/image/upload",
            formData).then(async (response) => {
                data = await response.data["secure_url"]
                console.log("ciaaaa")
                console.log(data)
                const inputData_ = inputData;
                if (inputData_.img_location[0] === "../../question-mark.svg") {
                    inputData_.img_location = []
                }
                inputData_.img_location.push(data)
                if (data !== "") { setInputData(prevValue => ({ ...prevValue, ...inputData_ })) };
            })
            .catch(error => {
                console.log(error)
                if (error.response?.data) {
                    if (error.response.data.error.message.includes("File size too large.")) {
                        alert("Failas per didelis")
                    }
                }
            })
        return data;
    }

    const handleUpdate = async () => {
        setIsLoading(true)
        if (isCreate) {
            // if (logo !== "") {
            //     inputData.imgLocation = logo;
            //     await profileUpload(logo)
            // }
            const response = await axios.post("https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/products", inputData)
                .then(response => {
                    console.log(response.status)
                    setIsLoading(false)
                    if (response.status === 200) {
                        navigate('/')
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
        else {
            // if (logo !== "") {
            //     inputData.imgLocation = logo;
            //     await profileUpload(logo)
            // }
            const url = "https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/products/" + inputData.id;
            await axios.put(url, inputData)
                .then(response => {
                    console.log(response)
                    setIsLoading(false)
                    if (response.status === 200) {
                        console.log(response.status)
                        navigate('/')
                    }


                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    const handleDelete = async () => {
        setIsLoading(true)
        if (paintingId === "0") {
            navigate('/')
        }
        await axios.delete("https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/products/" + inputData.id)
            .then(response => {
                console.log(response.status)
                setIsLoading(false)
                if (response.status === 200) {
                    navigate('/')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleDeleteImage = async (index) => {
        setIsLoading(true)
        const inputData_ = inputData;
        console.log(inputData_.img_location)
        inputData_.img_location.splice(index, 1)
        setInputData(prevValue => ({ ...prevValue, ...inputData_ }));
        setIsLoading(false)
    }


    const activateModal = () => {
        setModal(true)
    }

    const activateImgPreview = () => {
        setImgPreview(true);
    }

    return (
        <>

            {isLoading ? <div className={styles.loader}></div> : ""}
            <div className={`${styles.customerForm} ${isLoading ? styles.disabled : ""}`}>
                <div className={styles.backButton} onClick={() => { navigate('/') }} disabled={isLoading || isLoadingImage ? true : false}>{`< Back`}</div>
                <h2>Painting Information:</h2>

                <div className={`${styles.ingUpload} ${isLoadingImage ? styles.disabled : ""}`}>
                    {isLoadingImage ? <div className={styles.loaderMedium}></div> : ""}
                    <div className={styles.imgBox}>
                        {inputData.img_location !== undefined ?
                            inputData.img_location.map((element, index) =>
                                <div className={styles.images}>
                                    <img src={element} className={isLoadingImage ? styles.disabled : ""} alt="" />
                                    <span className={`${styles.deleteImg} ${isLoadingImage || isLoading ? styles.disabledButton : ""}`} onClick={() => handleDeleteImage(index)} disabled={isLoading || isLoadingImage ? true : false}><DeleteIcon /></span>
                                </div>

                            )
                            :
                            <></>
                        }

                    </div>
                    <ImgUpload
                        imageUpload={handleImg}
                        image={inputData.img_location ? inputData.img_location : ""}
                    />
                </div>
                <form disabled={isLoading ? true : false}>
                    <div className={styles.form}>
                        <div className={styles.twoInLine}>
                            <div className={styles.inputBox}>
                                <input
                                    id="label"
                                    type="text"
                                    placeholder=" "
                                    name="label"
                                    value={inputData.label}
                                    onChange={handleChange}
                                    onFocus={selectAll}
                                    required
                                    disabled={isLoading ? true : false}
                                //   className={`${styles.nameInput} ${(hovered ? styles.hoveredNameInput : "",
                                //     hovered && nameValid ? styles.validInput : "",
                                //     hovered && !nameValid ? styles.invalidInput : "")
                                //     }`}
                                />
                                <span>label</span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    id="price"
                                    type="text"
                                    placeholder=" "
                                    name="price"
                                    value={inputData.price}
                                    onChange={handleChange}
                                    onFocus={selectAll}
                                    min={0}
                                    max={9000000}
                                    step=".01"
                                    pattern="[0-9]*"
                                    inputmode="decimal"
                                    required
                                    disabled={isLoading ? true : false}
                                //   className={`${styles.nameInput} ${(hovered ? styles.hoveredNameInput : "",
                                //     hovered && nameValid ? styles.validInput : "",
                                //     hovered && !nameValid ? styles.invalidInput : "")
                                //     }`}
                                />
                                <span>price</span>
                            </div>
                        </div>
                        <div className={styles.twoInLine}>

                            <div className={styles.inputBox}>
                                <input
                                    id="size"
                                    type="text"
                                    placeholder=" "
                                    name="size"
                                    value={inputData.size}
                                    onChange={handleChange}
                                    onFocus={selectAll}
                                    required
                                    disabled={isLoading ? true : false}
                                //   className={`${styles.nameInput} ${(hovered ? styles.hoveredNameInput : "",
                                //     hovered && nameValid ? styles.validInput : "",
                                //     hovered && !nameValid ? styles.invalidInput : "")
                                //     }`}
                                />
                                <span>Size </span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    id="date"
                                    type="date"
                                    placeholder=" "
                                    name="creation date"
                                    value={dateToDisplay}
                                    onChange={handleChange}
                                    required
                                    disabled={isLoading ? true : false}
                                //   className={`${styles.nameInput} ${(hovered ? styles.hoveredNameInput : "",
                                //     hovered && nameValid ? styles.validInput : "",
                                //     hovered && !nameValid ? styles.invalidInput : "")
                                //     }`}
                                />
                                <span>Creation date</span>
                            </div>
                        </div>

                        <div className={styles.twoInLine}>

                            <div className={styles.inputBox} >
                                <input
                                    id="technique"
                                    type="text"
                                    placeholder=" "
                                    name="technique"
                                    value={inputData.technique}
                                    onChange={handleChange}
                                    onFocus={selectAll}
                                    required
                                    disabled={isLoading ? true : false}
                                //   className={`${styles.nameInput} ${(hovered ? styles.hoveredNameInput : "",
                                //     hovered && nameValid ? styles.validInput : "",
                                //     hovered && !nameValid ? styles.invalidInput : "")
                                //     }`}
                                />
                                < span > Technique </span>
                            </div>
                            <div className={styles.inputBox} style={{ width: '140px', padding: "0 5px" }}>
                                <input
                                    id="quantity"
                                    type="number"
                                    placeholder=" "
                                    name="quantity"
                                    value={inputData.quantity}
                                    onChange={handleChange}
                                    onFocus={selectAll}
                                    min={0}
                                    max={9000}
                                    step="1"
                                    pattern="[0-9]*"
                                    inputmode="decimal"
                                    required
                                    disabled={isLoading ? true : false}
                                />
                                <span>quantity</span>
                            </div>
                            <div className={styles.toggleButton}>
                                <h3 >Sold</h3>
                                <label className={styles.switch}>
                                    <input type="checkbox"
                                        id="sold"
                                        name="sold"
                                        checked={inputData.sold}
                                        onChange={handleChange}
                                        disabled={isLoading ? true : false} />
                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                </label>
                            </div>

                        </div>
                        <div className={styles.twoInLine}>

                            <div className={`${styles.deleteButton} ${isLoadingImage || isLoading ? styles.disabledButton : ""}`} onClick={() => handleDelete()} disabled={isLoading || isLoadingImage ? true : false}>Delete</div>

                            <div className={`${styles.saveButoon} ${isLoadingImage || isLoading ? styles.disabledButton : ""}`} onClick={() => handleUpdate()} disabled={isLoading || isLoadingImage ? true : false}>Save</div>
                        </div>
                    </div >
                </form >
            </div >
        </>
    )
}
import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes, useLocation, ScrollRestoration } from "react-router-dom"
import LoginLanding from './components/loginLanding/loginLanding';
import ProductDescription from './components/productDesctiption/productDescription';
import React, { useState, useEffect } from "react";
import { useCookies, Cookies } from 'react-cookie';


function App() {
  const [cookies] = useCookies(["products", "user"])

  const [products, setProducts] = useState([cookies.products ? cookies.products : {}])

  useEffect(() => {

  }, [products])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<>
          <LoginLanding products={products} setParentProducts={setProducts} />
        </>} />
        <Route exact path="/paintings" >
          <Route path=":paintingId" element={<><ProductDescription products={products} />
          </>}></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route exact path="/orders" >

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </div>

  );
}

export default App;

import React, { useState, useEffect } from "react";
import styles from "./productsList.module.scss"
import Product from "../product/product";
// import paintingsData from "../../paintingsData";
import { CookiesProvider, useCookies, withCookies } from "react-cookie";


export default function ProductsList(props) {
    const paintingsData = props.products;

    const [data, setData] = useState([paintingsData])
    const [sortType, setSortType] = useState('creationDate');
    const [productsLimit, setProductsLimit] = useState(props.limit ? props.limit : 6)
    const [cookies, setCookie] = useCookies(["user"]);

    // const passedData = props.soldPaintings ? paintingsData.filter(painting => painting.sold) : paintingsData
    useEffect(() => {

        const sortProducts = type => {
            const types = {
                price2l: "price",
                pricel2: "price",
                date12: "creationDate",
                date2l: "creationDate"
            };
            const sortProperty = types[type];
            // const requiredData = props.soldPaintings ? paintingsData.filter(painting => painting.sold) : paintingsData.filter(painting => !painting.sold)
            const requiredData = paintingsData;
            const sorted = [...requiredData].sort((a, b) => {
                if (sortProperty === "creationDate") {
                    console.log(a.creationDate)
                    const dateA = new Date(a.creationDate);
                    const dateB = new Date(b.creationDate);
                    return type === "date12" ? dateB - dateA : dateA - dateB;
                }
                else if (sortProperty === "price") {
                    console.log(a.price)
                    return type === "pricel2" ? a.price - b.price : b.price - a.price;
                }
            });
            console.log(sorted)
            setData(sorted)
        }
        sortProducts(sortType)
    }, [sortType]);

    // let products = document.querySelectorAll(`.${styles.block}`)
    // function scrollTrigger() {
    //     products.forEach(product => {
    //         if (product.offsetTop < window.scrollY) {
    //             // console.log(window.scrollY)
    //             product.classList.add(`${styles.visible}`)
    //         }
    //         else {
    //             // console.log(window.scrollY)

    //             product.classList.remove(`${styles.visible}`)
    //         }
    //     })
    // }
    // window.addEventListener('scroll', scrollTrigger)


    return (
        <div className={styles.pageLayout} id="paintingsList">
            <h2 className={styles.label}>{props.title}</h2>
            <div className={styles.productsFilter}>
                Filter:
                <select class={styles.selectBox} name="products-sorting" onChange={(e) => setSortType(e.target.value)}>
                    <option>Relevance</option>
                    <option value="pricel2">Price: Low - High</option>
                    <option value="price2l">Price: High - Low</option>
                    <option value="date12">Date: Newest - Oldest</option>
                    <option value="date2l">Date: Oldest - Newest</option>
                </select>
            </div>
            <div className={styles.card}>
                <div className={styles.block}>
                    <Product price={"new"} id="0"
                        imgLocation={["../plus-square.svg"]
                        }
                        updateCartCount={""} sold={""} />
                </div>
                {data.slice(0, productsLimit).toReversed().map((element) => (
                    <>
                        <div className={styles.block}>
                            <Product price={element.price} id={element.id}
                                imgLocation={element.img_location}
                                updateCartCount={element.updateCartCount} sold={element.sold} />
                        </div>
                    </>
                ))}


            </div>
            {productsLimit < data.length && props.allowedToLoadMore === "true" ?
                <div className={styles.loadMore} onClick={e => (setProductsLimit(productsLimit + 6))}>
                    <div className={styles.loadMoreButton}>Load more paintings</div></div>
                :
                ""
            }
        </div>
    )
}
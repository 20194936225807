import React, { useState, useEffect, createRef } from "react";
import styles from "./orders.module.scss"
import { useNavigate } from "react-router-dom";


export default function Orders(props) {

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});

    const handleShowModal = (props) => {

        setSelectedOrder(props);
        setShowModal(true);
    };

    return (
        <>


            {/* The modal */}
            {showModal && (
                <div id="orderModal" className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={() => setShowModal(false)}>
                            &times;
                        </span>
                        <div id="orderDetails">
                            <b>Order Id selected: {selectedOrder.orderId}</b>
                            <div>
                                <span className={styles.dataLabel}>Name: </span>
                                <span>{selectedOrder.customerName} {selectedOrder.customerSurname}</span>
                            </div>
                            <div>
                                <span className={styles.dataLabel}>E-mail: </span>
                                <span>{selectedOrder.customer.email}</span>
                            </div>
                            <div>
                                <span className={styles.dataLabel}>Phone number: </span>
                                <span>{selectedOrder.customer.phoneNumber}</span>
                            </div>
                            <div>
                                <span className={styles.dataLabel}>Total Price: </span>
                                <span>{selectedOrder.totalPrice}</span>
                            </div>
                            <div>
                                <span className={styles.dataLabel}>Items: </span>
                                <span>{selectedOrder.totalItems}</span>

                                {selectedOrder.products.map((element) => (
                                    <div className={styles.item}>
                                        <div className={styles.productInfo}>

                                            <div >
                                                <span className={styles.dataLabel}>Label: </span>
                                                <span>{element.label}</span>
                                            </div>
                                            <div>
                                                <span className={styles.dataLabel}>Price: </span>
                                                <span>{element.price}</span>
                                            </div>
                                            <div>
                                                <span className={styles.dataLabel}>Quanity: </span>
                                                <span>{element.quantity}</span>
                                            </div>
                                        </div>
                                        <img src={element.imgLocation} alt={element.label}></img>
                                    </div>
                                ))
                                }
                            </div>
                            <b>Address: </b>
                            <div className={styles.addressLayout}>

                                <div>
                                    <span className={styles.dataLabel}>Street: </span>
                                    <span>{selectedOrder.customer.street} </span>
                                </div>
                                <div>
                                    <span className={styles.dataLabel}>House no: </span>
                                    <span>{selectedOrder.customer.houseNo} </span>
                                </div>
                                <div>
                                    <span className={styles.dataLabel}>Flat no: </span>
                                    <span>{selectedOrder.customer.flatNo} </span>
                                </div>
                                <div>
                                    <span className={styles.dataLabel}>City: </span>
                                    <span>{selectedOrder.customer.postalCode} </span>
                                </div>
                                <div>
                                    <span className={styles.dataLabel}>Postal code: </span>
                                    <span>{selectedOrder.customer.city} </span>
                                </div>
                                <div>
                                    <span className={styles.dataLabel}>Country: </span>
                                    <span>{selectedOrder.customer.country} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className={`${styles.tableResponsive}`}>
                {/* <div className={styles.backButton} onClick={() => { navigate('/') }} >{`< Back`}</div> */}
                <h2>Orders Information:</h2>
                <table className={styles.table}>
                    <tr>
                        <th>Id</th>
                        <th>Customer</th>
                        <th>Total products</th>
                        <th>Total amount</th>
                        <th>Creation Date</th>
                        <th>Completetion Date</th>
                    </tr>
                    {props.orders.map((element) => (
                        <tr onClick={() => { handleShowModal(element) }}>
                            <td>{element.orderId}</td>
                            <td><b>{element.customerName} {element.customerSurname}</b></td>
                            <td>{element.totalItems}</td>
                            <td>{element.totalPrice} €</td>
                            <td>{String(element.creationDate).replace("T", " ")}</td>
                            <td>{element.completionDate ? String(element.completionDate).replace("T", " ") : "-"}</td>
                        </tr>
                    ))}
                </table>

            </div >



        </>
    )
}
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./loginLanding.module.scss"
import ProductsList from "../productsList/productsList";
import { CookiesProvider, useCookies, removeCookie } from "react-cookie";
import { Route } from "react-router-dom";
import Orders from "../orders/orders";


export default function LoginLanding(props) {
    const [productCookies, setProductCookie, removeCookie] = useCookies(["products", "user"]);
    const [userName, setUserName] = useState(productCookies.user ? productCookies.user.name : "");
    const [email, setEmail] = useState("");
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState(null);
    const [componentToDisplay, setComponentToDisplay] = useState("products")
    const [cookies, setCookie] = useCookies(["user"]);
    const [products, setProducts] = useState([{}])
    const [loading, setLoading] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [signupDone, setSignupDone] = useState(false);
    const [isSleeping, setIsSleeping] = useState(false)
    const [sleepingTimeLeft, setSleepingTimeLeft] = useState(0);
    const accessTokenExpiration = 1000000;
    const [orders, setOrders] = useState([{}])
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25)

    const getProducts = async () => {
        try {

            setLoading(true);
            const response = await fetch("https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/products", {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            const data = await response.json();
            setProducts(data)
            setProductCookie("products", data)
            await props.setParentProducts(data)
            setComponentToDisplay("products")
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setTimeout(() => {
                getProducts()
            }, "10000");
        }
    }

    const getOrders = async () => {
        try {

            setLoading(true);
            const response = await fetch(`https://hchdy40ig9.execute-api.eu-central-1.amazonaws.com/production/order?page=${page}&size=${pageSize}`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            const data = await response.json();
            setOrders(data.content)
            await props.setParentProducts(data)
            setComponentToDisplay("orders")
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setTimeout(() => {
                getOrders()
            }, "10000");
        }
    }

    useEffect(() => {
        accessTokenValidation();
        getProducts();
    }, [])

    useEffect(() => {
        if (sleepingTimeLeft > 0) {
            const sessionTimeout = setTimeout(() => {
                setSleepingTimeLeft(sleepingTimeLeft - 1);
            }, 1000)
            if (sleepingTimeLeft === 1) {
                logOut();
            }
            return () => {
                clearTimeout(sessionTimeout);
            }
        }
        else {
            setIsSleeping(false)
        }

    }, [sleepingTimeLeft])

    const updateTokenExpiration = () => {

        let data = cookies.user
        data.expiration = new Date(new Date().getTime() + accessTokenExpiration);
        setCookie("user", data, { path: "/" });
        setIsSleeping(false)
    }

    const accessTokenValidation = async (a, b) => {
        if (cookies.user && (cookies.user.email === "rapolas.skripkauskas@gmail.com" || cookies.user.email === "marius.kavolis@gmail.com")) {
            let timeLeft = (new Date(cookies.user.expiration).getTime() - new Date().getTime()) / 1000;
            timeLeft = Math.trunc(timeLeft);
            if (cookies.user.expiration < new Date().toISOString()) {
                return setIsAuthorized(false);
            }

            else if (timeLeft < 100) {
                setSleepingTimeLeft(timeLeft);
                setIsSleeping(true);
            }

            return setIsAuthorized(true);
        }
        else if (a && (b === "rapolas.skripkauskas@gmail.com" || b === "marius.kavolis@gmail.com")) {
            return setIsAuthorized(true);
        }
        else {
            return setIsAuthorized(false);
        }
    }

    const startSessionCountdown = () => {
        return (
            <div className={styles.sleepingBanner}>
                <h2>Are you asleep??</h2>
                <div>{sleepingTimeLeft}</div>
                <div className={styles.asleepButtons}>
                    <div onClick={() => updateTokenExpiration()} className={styles.headerButton}>No!</div>
                    <div onClick={() => logOut()} className={styles.headerButton}>Log Me Out!</div>
                </div>
            </div>
        )
    }

    const fetchUserData = async () => {
        if (user && signupDone) {
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    if (signupDone) {
                        setProfile(res.data);
                        setUserName(res.data.name);
                        setEmail(res.data.email);
                        let data = res.data;
                        data.expiration = new Date(new Date().getTime() + accessTokenExpiration);
                        setCookie("user", data, { path: "/" });
                    }
                    accessTokenValidation(res.data, res.data.email);
                })
                .catch((err) => console.log(err));
        }

    }

    const getContent = () => {
        switch (componentToDisplay) {
            case "products":
                if (loading) {
                    return (
                        <>
                            <div className={styles.loader}></div>
                        </>
                    );
                } else {
                    return (
                        <ProductsList products={products} limit={100} />
                    );
                }
            case "orders":
                if (loading) {
                    return (
                        <>
                            <div className={styles.loader}></div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Orders orders={orders} limit={100} />
                            <div className={styles.paginationLayout}>
                                <h3>Page:</h3>
                                <div className={styles.pageButton} onClick={() => page === 0 ? "" : setPage(page - 1)}>{`<`}</div>
                                <div className={styles.pageButton} onClick={() => setPage(page + 1)}>{`>`}</div>
                            </div>
                        </>
                    );
                }
            default:
                return <></>;
        }
    }

    useEffect(() => {
        getOrders()
    }, [page])


    //Google authorisation

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => { setUser(codeResponse); setSignupDone(true); console.log(codeResponse) },
        onError: (error) => console.log("Login Failed:", error),
    });

    const logOut = () => {
        googleLogout();
        setProfile(null);
        removeCookie("user", { path: "/" });
        setIsAuthorized(false);
        setSignupDone(false)
        setSleepingTimeLeft(0)
    };

    useEffect(() => {
        fetchUserData();
    }, [user]);

    useEffect(() => {
        accessTokenValidation();
    }, [cookies.user]);

    return (
        <>
            {isAuthorized ? <>
                {isSleeping ? startSessionCountdown() : ""}
                <div className={styles.container}>

                    <div className="welcome">Privet, {userName} </div>
                    <div className={styles.bo_header}>
                        <div className={styles.headerButton} onClick={() => loading ? "" : getProducts()}>Products</div>
                        <div className={styles.headerButton} onClick={() => loading ? "" : getOrders()}>Orders</div>
                    </div>
                    {
                        getContent()
                    }

                </div>
            </> :
                <>
                    <div className={styles.pageLayout}>
                        <div className={styles.googleLogIn} onClick={() => login()}>
                            Sign in with Google 🚀{" "}
                        </div>
                    </div>
                </>
            }
        </>
    )

}
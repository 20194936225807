import React, { useState, useEffect } from "react";
import styles from "./product.module.scss"
// import Modal from "../modal/modal";
import { Link } from "react-router-dom";


export default function Product(props) {

    // let products = document.querySelectorAll(`.${styles.card}`)
    // function scrollTrigger() {
    //     products.forEach(product => {
    //         if (product.offsetTop < window.scrollY) {
    //             product.classList.add(`${styles.active}`)
    //         }
    //         else {
    //             product.classList.remove(`${styles.active}`)
    //         }
    //     })
    // }
    // window.addEventListener('scroll', scrollTrigger)

    const formatedPrice = (number) => {
        return (number.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        )
    }

    return (
        <>
            <Link to={`/paintings/${props.id}`} style={{ textDecoration: 'none' }}>
                <div className={`${styles.container}`}>

                    <div className={`${styles.card}`} key={props.title}>
                        <div className={styles.imgBox}>
                            <img src={props.imgLocation ? props.imgLocation[0] : ""} alt="" />
                        </div>
                        <div className={`${styles.content}`}>
                            <div className={styles.label}>{props.label}</div>
                            <div className={styles.price}>{`${props.sold ? "Sold" : !isNaN(props.price) ? formatedPrice(parseFloat(props.price)) + " €" : ""} `}</div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}